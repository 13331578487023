<template>
  <b-row class="match-height">
    <b-col
      lg="9"
      class="space_"
    >
      <ListLessonApply />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ListLessonApply from '@/components/student/lessonApply/ListLessonApply.vue'

export default {
  components: {
    BRow,
    BCol,
    ListLessonApply,
  },
  data() {
    return {
    }
  },
  computed: {
  },
}
</script>

<style scoped>
.space_ {
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 100%;
  }
}
</style>

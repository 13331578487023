import firebase from 'firebase/app'
import store from '@/store'

export default function StudentLessonList() {
  const dataList = []

  return {
    async  getAllDataList() {
      try {
        await firebase.firestore().collection('lesson')
          .where('studentUid', 'array-contains', store.getters['userBasic/uid'])
          .orderBy('insertTime', 'desc')
          .get()
          .then(sn => {
            if (!sn.empty) {
              sn.forEach(doc => {
                dataList.push(doc.data())
              })
            }
          })

        const response = {
          status: 'success',
          data: dataList,
        }
        return response
      } catch (error) {
        const response = {
          status: 'error',
          msg: error,
        }
        return response
      }
    },
  }
}

<template>
  <div>
    <div>
      <h2 class="">
        申込済サービス一覧
      </h2>
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body mt-1 mb-2">
          申込済のサービスを確認できます。
        </div>
      </b-alert>
      <!-- <div class="ml-1">
        <b-row>
          <b-col lg="1">
            <div class="mt-2">
              <b>方法</b>
            </div>
          </b-col>
          <b-col>
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-for="(it, index) in selectB"
                :key="index"
                v-model="selectedB"
                :value="it.value"
                @input="filerData"
              >
                {{ it.choiceLabel }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="ml-1 mb-2">
        <b-row>
          <b-col lg="1">
            <div class="mt-2">
              <b>コメント</b>
            </div>
          </b-col>
          <b-col>
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-for="(it, index) in selectC"
                :key="index"
                v-model="selectedC"
                :value="it.value"
                @input="filerData"
              >
                {{ it.choiceLabel }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div> -->
      <div class="ard_">
        <h3
          v-if="dataList.length === 0"
        >
          表示するデータはありません。
        </h3>
        <b-card
          v-for="(item, index) in dataList"
          v-else
          :key="index"
          title=""
          variant="outline-primary"
          class="card_"
        >
          <h3 class="">
            <b-row>
              <b-col xl="6">
                <!-- <feather-icon
                  icon="UsersIcon"
                  size="24"
                  class="text-primary"
                /> -->
                <b-avatar
                  :src="item.lessonPic"
                />
                {{ item.lessonName }}
              </b-col>
              <b-col
                xl="6"
                class="text-right"
              >
                <span class="text-primary"><b>{{ Number(item.coin).toLocaleString() }}</b></span><small>コイン</small>
                <!-- {{ item.coin.toLocaleString() }} <small>コイン</small> -->
              </b-col>
            </b-row>
          </h3>
          <span class="day_">
            {{ item.startTime | formatDate }}
          </span>
          <span class="time_ text-primary">
            <b>{{ item.lessonDdateLabel }}</b>
          </span>
          <b-badge
            variant="light-danger"
            class="ml-1"
          >
            コメント{{ item.isComment ? '済み' : '未完了' }}
          </b-badge>
          <b-badge
            v-if="item.lessonCategory"
            variant="light-primary"
            class="ml-1"
          >
            {{ item.lessonCategory.labelName }}
          </b-badge>
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="submit"
              variant="primary"
              size="sm"
              @click="detail(item.docID)"
            >
              確認する
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton, BAlert, BBadge, BAvatar,
} from 'bootstrap-vue'
import dateformate from '@/components/js/filter/formatDateDayOnly'
import StudentLessonList from '@/firestore/lesson/getStudentLessonList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
    BBadge,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDate(val) {
      return dateformate(val)
    },
  },
  props: {
    // dataList: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      dataList: [],
      fields: [],
      lessonList: [],
    }
  },
  async mounted() {
    const res = new StudentLessonList()
    const response = await res.getAllDataList()
    if (response.status === 'success') {
      this.lessonList = response.data
      // if (this.selected.length === 0) this.fields = this.lessonList
    } else {
      window.console.log('error', response.msg)
    }
    this.dataList = this.lessonList
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/lesson', query: { id } })
    },
    show() {

    },
  },
}
</script>

<style scoped>
.card_ {
  width: 100%;
  /* margin: 1% 1%; */
}
.day_ {
  font-size: 1rem;
  font-weight: 600;
}
.time_ {
  font-size: 1.4rem;
}
</style>
